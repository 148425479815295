import { QuestionnaireTemplate, QuestionDefinition, ValueQuestionDefinition, VariantFieldType } from '@dims/components';

/** find bugs in question template */
function validateVariants(template: QuestionnaireTemplate) {
  function analyzeQuestion(question: QuestionDefinition) {
    function findVariant(variant: VariantFieldType) {
      return question.questions?.find((q) => q.variant === variant);
    }
    function assertVariantExists(variant: VariantFieldType) {
      const sub1 = findVariant(variant);
      if (!sub1) {
        console.error('Question missing variant field', question.questionDefinitionId, variant);
      }
    }
    function assertEqual(expected: string, actual: string | null | undefined) {
      if (expected !== actual) {
        console.error('Question', question.questionDefinitionId, question.title);
        console.error('Expected:', expected);
        console.error('But was:', actual);
      }
    }
    function assertChildren(expected: number) {
      const actual = question.questions?.length ?? 0;
      if (expected !== actual) {
        console.error('Question', question.questionDefinitionId, question.title);
        console.error('Expected child count:', expected);
        console.error('But was:', actual);
      }
    }
    if ((question.title === 'Skal der ændres i teksten?' && question.variant !== 'override-texts')
      || (question.variant === 'override-texts' && question.title !== 'Skal der ændres i teksten?')
    ) {
      console.error('mismatch for override-texts', question.questionDefinitionId);
    }
    if (question.variant) {
      if (question.variant === 'requirement-variant-2'
      || question.variant === 'requirement-variant-3-contract'
      || question.variant === 'requirement-variant-3-evaluation') {
        assertVariantExists('override-texts');
        assertVariantExists('custom-requirement-text');
        const customText = findVariant('custom-requirement-text') as ValueQuestionDefinition | undefined;
        if (customText?.text && customText.text !== question.text) {
          console.error('custom-requirement-text mismatch', question.title, customText.questionDefinitionId);
        }
      }
      if (question.variant === 'requirement-variant-1') {
        assertChildren(0);
      }
      if (question.variant === 'requirement-variant-2') {
        assertChildren(2);
      }
      if (question.variant === 'requirement-variant-3-contract'
        || question.variant === 'requirement-variant-3-evaluation') {
        assertVariantExists('include-requirement');
      }
      if (question.variant === 'requirement-variant-3-contract') {
        assertChildren(3);
      }
      if (question.variant === 'requirement-variant-3-evaluation') {
        assertVariantExists('default-evaluation-text');
        assertVariantExists('custom-evaluation-text');
        const evalText = findVariant('custom-evaluation-text') as ValueQuestionDefinition | undefined;
        if (evalText?.text && evalText.text !== evalText.defaultValue) {
          console.error('custom-evaluation-text mismatch', question.title, evalText.questionDefinitionId);
        }
        assertChildren(5);
      }
      if (question.variant === 'requirement-variant-4') {
        assertVariantExists('requirement4-repeater');
        assertChildren(1);
        const rep = findVariant('requirement4-repeater');
        if (rep) {
          const rq = rep.questions as [QuestionDefinition, QuestionDefinition, QuestionDefinition, QuestionDefinition];
          assertEqual('4', rq.length.toString());
          assertEqual('requirement-type', rq[0].variant);
          assertEqual('requirement-title', rq[1].variant);
          assertEqual('requirement-text', rq[2].variant);
          assertEqual('evaluation-text', rq[3].variant);
        }
      }
      if (question.variant === 'requirement-variant-5') {
        assertChildren(1);
        assertVariantExists('include-requirement');
      }
      if (question.variant === 'requirement-variant-6') {
        assertChildren(2);
        assertVariantExists('include-requirement');
        assertVariantExists('requirement-textfield');
      }
      if (question.variant === 'requirement-variant-flex') {
        assertChildren(10);
        assertVariantExists('requirement-flex-type');
        assertVariantExists('requirement-flex-locked');
        assertVariantExists('include-requirement');
        assertVariantExists('default-requirement-description');
        assertVariantExists('default-evaluation-requirement-description');
        assertVariantExists('default-evaluation-text');
        assertVariantExists('override-texts');
        assertVariantExists('custom-requirement-description');
        assertVariantExists('custom-evaluation-requirement-description');
        assertVariantExists('custom-evaluation-text');
      }
      if (question.variant === 'requirement-variant-7') {
        assertVariantExists('default-evaluation-text');
        assertChildren(1);
      }
      if (question.variant === 'requirement-variant-8') {
        assertVariantExists('default-evaluation-text');
        assertVariantExists('custom-evaluation-text');
        const evalText = findVariant('custom-evaluation-text') as ValueQuestionDefinition | undefined;
        if (evalText?.text && evalText.text !== evalText.defaultValue) {
          console.error('custom-evaluation-text mismatch', question.title, evalText.questionDefinitionId);
        }
        assertChildren(4);
      }
    }
    if (question.questions) {
      question.questions.forEach(analyzeQuestion);
    }
  }
  template.questions.forEach(analyzeQuestion);
}

function traverse(questions: QuestionDefinition[], f: (q: QuestionDefinition) => void): void {
  questions.forEach((q) => {
    f(q);
    traverse(q.questions ?? [], f);
  });
}

function detectDuplicates(template: QuestionnaireTemplate) {
  const ids = new Set<string>();
  traverse(template.questions, (q) => {
    if (ids.has(q.questionDefinitionId)) {
      console.error('Duplicate ID:', q.questionDefinitionId);
    } else {
      ids.add(q.questionDefinitionId);
    }
  });
}

export default function validateStructure(template: QuestionnaireTemplate) {
  console.info('Validating questionnaire structure');
  detectDuplicates(template);
  validateVariants(template);
  console.info('Validating questionnaire structure complete');
}
