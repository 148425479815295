<template>
  <div class="bootstrap">
    <code>Testside til HoT embedded questionnaire</code>
    <v-text-field v-model="role" label="Role" />
    <v-btn @click="loadTemplates">Load templates</v-btn>
    <div v-if="templates">
      <div v-for="template in templates" :key="template.questionnaireTemplateId">
        <div>{{ template.title }} {{ template.questionnaireTemplateId }}
          <v-btn @click="createQuestionnaireAnswers(template.questionnaireTemplateId)">Create questionnaire</v-btn>
        </div>
      </div>
    </div>

    <v-btn @click="loadQuestionnaires">Load questionnaires</v-btn>
    <v-list v-if="questionnaires">
      <v-list-item v-for="questionnaire in questionnaires" :key="questionnaire.id" @click="editInFrame(questionnaire.id)">
        <v-list-item-title>{{ questionnaire.applicationName }} {{ questionnaire.id }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <div>
      <iframe style="width:100%" title="hotspa" ref="hotspa" src="http://localhost:8300"></iframe>
    </div>

    <v-btn @click="postTokenToFrame">Pass token to frame</v-btn>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, useTemplateRef } from 'vue';
import questionnaireService, { QuestionnaireAnswersSummary, TemplateInfo } from './questionnaire.service';
import { SSOUser, useBackend } from '@dims/components';

const backend = useBackend();
const role = ref('');
const templates = ref<TemplateInfo[]>([]);
const questionnaires = ref<QuestionnaireAnswersSummary[]>([]);
const frame = useTemplateRef('hotspa');

async function loadTemplates() {
  templates.value = await questionnaireService.getTemplatesForRole(role.value);
}
async function loadQuestionnaires() {
  questionnaires.value = await questionnaireService.getQuestionnaireAnswersForUser();
}

async function createQuestionnaireAnswers(templateId: string) {
  const user = await backend.authenticatedClient.getUser();
  if (!user?.org?.organizationNumber) {
    throw new Error('organizationNumber missing');
  }
  const questionnaire = await questionnaireService.createQuestionnaire(templateId, user.org.organizationNumber);
  await editInFrame(questionnaire.id);
}

const user = ref<SSOUser>();
onMounted(async () => {
  user.value = await backend.authenticatedClient.getUser() ?? undefined;
});

async function editInFrame(questionnaireId: string) {
  const token = await backend.authenticatedClient.getAccessToken();
  sendMessage({message: "Hello frame", command: 'editQuestionnaire', questionnaireId, token: token });
}

async function postTokenToFrame() {
  const token = await backend.authenticatedClient.getAccessToken();
  sendMessage({message: "Hello frame", token: token });
}

function sendMessage(message: object) {
  // Unsafe because postMessage message parameter is 'any'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  frame.value?.contentWindow?.postMessage(message, 'http://localhost:8300');
}

</script>

<style scoped>

.bootstrap {
  border: solid red 2px;
  font-family: 'Courier New', Courier, monospace;
  padding: 2em;
}

</style>
