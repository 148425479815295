import { useBackend, Questionnaire } from '@dims/components';

export interface TemplateInfo {
  readonly questionnaireTemplateId: string;
  readonly title: string;
}

export interface QuestionnaireAnswersSummary {
  readonly id: string;
  readonly templateId: string;
  readonly applicationName: string;
}

export default {
  getApi() {
    const backend = useBackend();
    return backend.authenticatedClient.getAuthorizedInstance();
  },
  async getTemplatesForRole(role: string) {
    const response = await this.getApi().get<TemplateInfo[]>(
      `questionnaire-template?role=${role}`,
    );
    return response.data;
  },
  async getQuestionnaireAnswersForUser() {
    const response = await this.getApi().get<QuestionnaireAnswersSummary[]>(
      `questionnaire-answer`,
    );
    return response.data;
  },
  async createQuestionnaire(questionnaireTemplateId: string, organizationNumber: string) {
    const response = await this.getApi().post<Questionnaire>(
      `questionnaire-template/${questionnaireTemplateId}/questionnaire-answer`,
      { organizationId: organizationNumber },
    );
    return response.data;
  },
  async updateQuestionnaire(questionnaireTemplateId: string, questionnaireAnswersId: string) {
    const response = await this.getApi().patch<Questionnaire>(
      `questionnaire-template/${questionnaireTemplateId}/questionnaire-answer/${questionnaireAnswersId}`,
      {}
    );
    return response.data;
  },
};
