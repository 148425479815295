<template>
  <tr :key="templateSummary.id" class="my-1">
    <td class="text-left">{{ displayLastUpdated(templateSummary) }}</td>
    <td>{{ templateSummary.lastUpdatedBy?.fullName }}</td>
    <td class="published-name-column">{{ templateSummary.publishedName }}</td>
    <td class="py-2">
      <v-btn @click="editTemplate(templateSummary)" class="primary-button-mini ml-2"><v-icon>mdi-pencil-outline</v-icon>Rediger kladde</v-btn>
      <PublishDraftDialog :draft="templateSummary" @updated="refresh()" />
      <!-- delete draft -->
      <ConfirmDialog
        activatorText="Slet kladde"
        text="Vil du slette kladde?"
        @confirmed="deleteDraft(templateSummary)"
      />
      <CopyToEnvironment :templateId="templateSummary.id" class="ml-2" />
    </td>
    <td>
      {{ templateSummary.id.slice(0, 8) }}...
      <CopyToClipboard :text="templateSummary.id" /></td>
  </tr>
</template>

<script setup lang="ts">

import { QuestionnaireTemplateSummary } from '@/components/Templates/QuestionnaireTemplateSummary';
import CopyToEnvironment from './CopyToEnvironment.vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';
import PublishDraftDialog from './PublishDraftDialog.vue';
import { useRouter } from 'vue-router';
import templateServices from '@/components/Templates/templateServices';
import { DateTime } from 'luxon';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';

const emit = defineEmits<{ updated: [] }>();
const { templateSummary } =
  defineProps<{ templateSummary: QuestionnaireTemplateSummary }>();

const router = useRouter();

function displayLastUpdated(template?: QuestionnaireTemplateSummary) {
  if (template) {
    return DateTime.fromISO(template.lastUpdated).toFormat('dd-MM-yyyy HH:mm');
  }
  return '';
}

async function editTemplate(template?: QuestionnaireTemplateSummary) {
  if (template && template.state === 'Draft') {
    await router.push(`/template/${template.id}`);
  }
}

async function deleteDraft(template?: QuestionnaireTemplateSummary) {
  if (template) {
    await templateServices.deleteTemplate(template.id);
    emit('updated');
  }
}

function refresh() {
  emit('updated');
}

</script>

<style scoped>

td {
  background-color: #E2E4E3;
  color: #63756B;
}

td:nth-child(1) {
  width: 170px;
}

td:nth-child(2) {
  min-width: 30ch;
}

td.published-name-column {
  width: 18%;
}

</style>
