<template>
  <v-checkbox
    v-model="selected"
    @update:model-value="onChange"
    label="Valgfrit felt"
    persistent-hint
    hint="Dette betyder at feltet er valgfrit at udfylde."
    :disabled="readonly"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const emit = defineEmits<{ optionalFieldSelectorChanged: [boolean | null] }>();
const { value = false, readonly = false } = defineProps<{
    value?: boolean,
    readonly?: boolean }>();
const selected = ref(false);

onMounted(() => {
  selected.value = value;
});

function onChange(newValue: boolean | null) {
  emit('optionalFieldSelectorChanged', newValue);
}

</script>
