import { QuestionTypeName, QuestionTypesEnum, VariantType } from '@dims/components';

const sectionIcon = 'mdi-text';
const repeatIcon = 'mdi-repeat';
const numberIcon = 'mdi-pound';
const radioBtnIcon = 'mdi-radiobox-marked';
const uploadFileIcon = 'mdi-file-upload';
const requirementIcon = 'mdi-package-variant-closed';
const pricePointSetIcon = 'mdi-list-box';
const placeholderIcon = 'mdi-square-rounded-outline';

export interface QuestionTypeCard {
  type: QuestionTypeName;
  title: string;
  description?: string;
  icon?: string;
  variant?: VariantType;
}
export interface QuestionCategory {
  title: string;
  cards: QuestionTypeCard[];
}

export const questionCardCategories = (flexRequirementsEnabled: boolean): QuestionCategory[] => [
  {
    title: '',
    cards: [
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Sektion',
        description: 'Tilføj en ny sektion på samme niveau eller nyt niveau. Sektionen indeholder titel, beskrivelse, i-tooltip samt afhængighed til andre niveauer',
        icon: sectionIcon,
      },
      {
        type: QuestionTypesEnum.REPEATER,
        title: 'Repeater sektion',
        description: 'Tilføj en ny repeater sektion. Sektionens indhold er dynamisk og defineres ved at tilføje varianter under denne',
        icon: repeatIcon,
      },
      {
        type: QuestionTypesEnum.TEXT,
        title: 'Tekstfelt',
        description: 'Tilføj et tekstfelt, der indeholder en titel og et tekstfelt der kan formatteres',
        icon: 'mdi-form-textarea',
      },
      {
        type: QuestionTypesEnum.SIMPLETEXT,
        title: 'Simpel tekst',
        description: 'Tilføj et simplet tekstfelt, der indeholder tekst uden formattering og linjeskift',
        icon: 'mdi-form-textbox',
      },
      {
        type: QuestionTypesEnum.NUMBER,
        title: 'Numerisk værdi',
        description: 'Tilføj et numerisk felt, der indeholder en titel og et numerisk felt',
        icon: 'mdi-pound',
      },
      {
        type: QuestionTypesEnum.BOOLEAN,
        title: 'Afkrydsningsfelt',
        description: 'Tilføj et afkrydsningsfelt',
        icon: 'mdi-checkbox-marked',
      },
      {
        type: 'BooleanOptions',
        title: 'Ja/Nej valgmulighed',
        description: 'Tilføj radioknapper med Ja og Nej.',
        icon: radioBtnIcon,
      },
      {
        type: 'Section',
        title: 'Liste af afkrydsningsfelter',
        description: 'Liste af afkrydsningsfelter. Kunden kan markere et vilkårligt antal.',
        icon: 'mdi-format-list-checks',
        variant: 'checkbox-list',
      },
      {
        type: QuestionTypesEnum.OPTIONS,
        title: 'Flere valgmuligheder',
        description: 'List af valgmuligheder som vises som radioknapper. Kunden skal vælge én af valgmulighederne.',
        icon: radioBtnIcon,
      },
      {
        type: 'Date',
        title: 'Dato',
        description: 'Tilføj datofelt',
        icon: 'mdi-calendar',
      },
    ] },
    {
      title: 'Avanceret',
      cards: [
        {
          type: QuestionTypesEnum.PLACEHOLDER,
          title: 'Placeholder',
          description: 'Indsæt et custom component (02.19)',
          icon: placeholderIcon,
        },
        {
          type: QuestionTypesEnum.AGGREGATOR,
          title: 'Aggregator',
          description: 'Tilføj et element som udstiller og validerer et andet felt på tværs af repeateritems',
          icon: 'mdi-view-list',
        },
      ] },
  {
    title: 'Prispunkter',
    cards: [
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Mængderabat',
        description: 'Tilføj mængderabat: Leverandøren kan angive rabatprocentsatser for rabattrin 1 og 2',
        icon: numberIcon,
        variant: 'supplier-quantity-discount',
      },
      {
        type: QuestionTypesEnum.INTERVALS,
        title: 'Intervaller',
        description: 'Tilføj intervaller til prispunkter (antal år eller milepæle)',
        icon: pricePointSetIcon,
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Prispunkter med gentagelse',
        description: 'Tilføj prispunkter med gentagelse: Kunden kan angive, hvor mange prispunkt-spørgsmål med hvert sit interval-spørgsmål, der skal oprettes',
        icon: pricePointSetIcon,
        variant: 'customer-pricepoint-with-repeater',
      },
      {
        type: QuestionTypesEnum.PRICEPOINTSET,
        title: 'Prispunkt',
        description: 'Tilføj prispunkter: Kunden skal angive forecast for de prispunkter, som der ønskes tilbud på. Leverandøren bliver bedt om at afgive priser på de tilvalgte prispunkter',
        icon: pricePointSetIcon,
      },
      {
        type: QuestionTypesEnum.PRICEPOINTSET,
        title: 'Prispunktvariant 1',
        description: 'Skjul værdier for kunden',
        icon: 'mdi-asterisk',
        variant: 'pricepoint-hide-customer-values',
      },
      {
        type: QuestionTypesEnum.PRICEPOINTSET,
        title: 'Prispunktvariant 2',
        description: 'Skjul spørgsmål for kunden',
        icon: 'mdi-asterisk',
        variant: 'pricepoint-hide-customer-question',
      },
      {
        type: QuestionTypesEnum.PRICEPOINTSET,
        title: 'Prispunktvariant 3',
        description: 'Skjul spørgsmål for kunden og ingen intervaller',
        icon: 'mdi-asterisk',
        variant: 'pricepoint-hide-customer-question-no-intervals',
      },
    ] },
  {
    title: 'Filer',
    cards: [
      {
        type: QuestionTypesEnum.FILEDOWNLOAD,
        title: 'SKI fil upload',
        description: 'SKI kan uploade filer, der skal være tilgængelige for kunden',
        icon: uploadFileIcon,
      },
      {
        type: QuestionTypesEnum.FILES,
        title: 'Kunde fil upload',
        description: 'Kunden kan uploade filer, der skal være tilgængelige for leverandøren',
        icon: uploadFileIcon,
      },
      {
        type: QuestionTypesEnum.SECTION,
        variant: 'supplier-file-upload',
        title: 'Leverandør fil upload',
        description: 'Leverandøren kan uploade filer, der skal være tilgængelige for kunden.',
        icon: uploadFileIcon,
      }],
  },
  {
    title: 'Krav',
    cards: [
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Låst kontraktkrav',
        description: 'Låst kontraktkrav - ufravigeligt',
        icon: requirementIcon,
        variant: 'requirement-variant-1',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Tilpasset kontraktkrav',
        description: 'Delvist låst kontraktkrav - det er muligt for kunden at ændre i teksten, men kravet kan ikke fravælges',
        icon: requirementIcon,
        variant: 'requirement-variant-2',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Valgfrit tilpasset kontraktkrav',
        description: 'Valgfrit kontraktkrav. Valgfrit for kunden, om kravet skal indgå. SKI vælger om kravet som udgangspunkt indgår, men kunden kan ændre dette. Kunden kan tilpasse kravteksten.',
        icon: requirementIcon,
        variant: 'requirement-variant-3-contract',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Valgfrit Evalueringskrav',
        description: 'Valgfrit Evalueringskrav - seperate felter til kravtekst og evelueringstekst. Valgfrit for kunden, om kravet skal indgå. SKI vælger om kravet som udgangspunkt indgår, men kunden kan ændre dette.',
        icon: requirementIcon,
        variant: 'requirement-variant-3-evaluation',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Kundens egne krav',
        description: 'Kunden kan her indsætte et valgfrit antal kontraktkrav eller evalueringskrav, som kunden selv formulurer.',
        icon: requirementIcon,
        variant: 'requirement-variant-4',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Valgfrit Kontraktkrav',
        description: 'Valgfrit kontraktkrav. Kunden vælger om kravet indgår eller ej.',
        icon: requirementIcon,
        variant: 'requirement-variant-5',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Valgfrit krav med ekstra tekstfelt',
        description: 'Valgfrit for kunden, om kravet skal indgå. Hvis kravet indgår, tilføjes et obligatorisk tekstfelt.',
        icon: requirementIcon,
        variant: 'requirement-variant-6',
      },
      ...(flexRequirementsEnabled ? [
        {
          type: QuestionTypesEnum.SECTION,
          title: 'Flexkrav',
          description: 'Fleksibelt krav - kontraktkrav eller evalueringskrav',
          icon: requirementIcon,
          variant: 'requirement-variant-flex' as VariantType,
        },
      ] : []),
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Låst evalueringskrav',
        description: 'Låst evalueringskrav - ufravigeligt',
        icon: requirementIcon,
        variant: 'requirement-variant-7',
      },
      {
        type: QuestionTypesEnum.SECTION,
        title: 'Tilpasset evalueringskrav',
        description: 'Delvist låst evalueringskrav - det er muligt for kunden at ændre i teksten, men kravet kan ikke fravælges',
        icon: requirementIcon,
        variant: 'requirement-variant-8',
      },
    ] },
];

export function labelForQuestionTypeEnum(questionType: QuestionTypeName) {
  switch (questionType) {
    case QuestionTypesEnum.SECTION:
      return 'Sektion';
    case QuestionTypesEnum.TEXT:
      return 'Tekstfelt';
    case QuestionTypesEnum.SIMPLETEXT:
      return 'Simpel tekst';
    case QuestionTypesEnum.NUMBER:
      return 'Numerisk værdi';
    case QuestionTypesEnum.BOOLEAN:
      return 'Afkrydsningsfelt';
    case 'BooleanOptions':
      return 'Ja/Nej valg';
    case 'Date':
      return 'Dato';
    case QuestionTypesEnum.OPTIONS:
      return 'Valgmuligheder';
    case QuestionTypesEnum.REPEATER:
      return 'Repeater';
    case QuestionTypesEnum.FILES:
      return 'Kunde fil upload';
    case QuestionTypesEnum.FILEDOWNLOAD:
      return 'SKI fil upload';
    case QuestionTypesEnum.PRICEPOINTSET:
      return 'Prispunkter';
    case QuestionTypesEnum.INTERVALS:
      return 'Intervaller';
    case QuestionTypesEnum.PLACEHOLDER:
      return 'Placeholder';
    case QuestionTypesEnum.AGGREGATOR:
      return 'Aggregator';
    default:
      return 'Krav';
  }
}
