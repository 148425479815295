<template>
  <v-row>
    <v-col>
      <NumericInput
        :storeAsString="true"
        v-model="question.defaultValue"
        :disabled="readonly"
        variant="outlined"
        label="Default værdi"
        :minValue="question.minValue"
        :maxValue="question.maxValue"
        :decimals="question.decimals"
      />
    </v-col>
    <v-col>
      <NumericInput
        v-if="!crossValidationMinEnabled"
        v-model="question.minValue"
        :disabled="readonly"
        variant="outlined"
        label="Minimum"
        :minValue="-9999"
        :maxValue="question.maxValue"
        :decimals="question.decimals"
      />
      <v-text-field
        v-if="crossValidationMinEnabled"
        v-model="crossValidationMinTitle"
        :disabled="readonly"
        :readonly="true"
        :rules="[isCrossValidationMinValid]"
        validate-on="eager"
        variant="outlined"
        label="Minimum spg. id"
        @click="showCrossValidationDialogMin = true"
      />
      <CrossValidationDialog
        v-model="showCrossValidationDialogMin"
        :question="question"
        :initialSelectedSource="crossValidationMinValue"
        :template="template"
        crossValidationRule="MinValue"
        @confirmed="setCrossValidationRule"
        @cancelled="showCrossValidationDialogMin = false"
      />
      <v-checkbox
        v-model="crossValidationMinEnabled"
        label="Krydsvalidering"
        persistent-hint
        :disabled="readonly"
        class="mt-n5"
        @change="enableCrossValidationRule('MinValue')"
      />
    </v-col>
    <v-col>
      <NumericInput
        v-if="!crossValidationMaxEnabled"
        v-model="question.maxValue"
        :disabled="readonly"
        variant="outlined"
        label="Maksimum"
        :minValue="question.minValue"
        :decimals="question.decimals"
      />
      <v-text-field
        v-if="crossValidationMaxEnabled"
        v-model="crossValidationMaxTitle"
        :disabled="readonly"
        :readonly="true"
        :rules="[isCrossValidationMaxValid]"
        validate-on="eager"
        variant="outlined"
        label="Maximum spg. id"
        @click="showCrossValidationDialogMax = true"
      />
      <CrossValidationDialog
        v-model="showCrossValidationDialogMax"
        :question="question"
        :initialSelectedSource="crossValidationMaxValue"
        :template="template"
        crossValidationRule="MaxValue"
        @confirmed="setCrossValidationRule"
        @cancelled="showCrossValidationDialogMax = false"
      />
      <v-checkbox
        v-model="crossValidationMaxEnabled"
        label="Krydsvalidering"
        persistent-hint
        :disabled="readonly"
        class="mt-n5"
        @change="enableCrossValidationRule('MaxValue')"
      />
    </v-col>
    <v-col>
      <NumericInput
        v-model="question.decimals"
        :disabled="readonly"
        variant="outlined"
        label="Maks. decimaler (blank = heltal)"
      />
    </v-col>
    <v-col>
      <v-checkbox
        v-model="inSumValidation"
        label="Andel af 100"
        :disabled="readonly"
      />
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { NumericQuestionDefinition, NumericInput, QuestionnaireTemplate } from '@dims/components';
import { computed, ref, onMounted } from 'vue';
import CrossValidationDialog from '../CrossValidationDialog.vue';
import { filterForCrossValidationQuestions } from '@/utils';

const { template, question, readonly = false } = defineProps<{
  template: QuestionnaireTemplate,
  question: NumericQuestionDefinition,
  readonly?: boolean,
}>();

const crossValidationMinEnabled = ref<boolean>(false);
const crossValidationMaxEnabled = ref<boolean>(false);

const crossValidationMinValue = createCrossValidationRule('MinValue');
const crossValidationMaxValue = createCrossValidationRule('MaxValue');

const showCrossValidationDialogMin = ref<boolean>(false);
const showCrossValidationDialogMax = ref<boolean>(false);

onMounted(() => {
  if (question.crossValidations?.filter(cv => cv.crossValidationRule == "MinValue" && cv.enabled).length) {
    crossValidationMinEnabled.value = true;
  }
  if (question.crossValidations?.filter(cv => cv.crossValidationRule == "MaxValue" && cv.enabled).length) {
    crossValidationMaxEnabled.value = true;
  }
});

const crossValidationMinTitle = computed(() => {
  return filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === crossValidationMinValue.value)?.title ?? '';
});

const crossValidationMaxTitle = computed(() => {
  return filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === crossValidationMaxValue.value)?.title ?? '';
});

function setCrossValidationRule(crossValidationRule: string, questionDefinitionId: string) {
  if (crossValidationRule == "MinValue") {
    showCrossValidationDialogMin.value = false;
    crossValidationMinValue.value = questionDefinitionId;
  } else if (crossValidationRule == "MaxValue") {
    showCrossValidationDialogMax.value = false;
    crossValidationMaxValue.value = questionDefinitionId;
  }
}

function enableCrossValidationRule(ruleName: string) {
  const rule = question.crossValidations?.find(cv => cv.crossValidationRule === ruleName);

  if (rule) {
    rule.enabled = ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value;
  }
}

function createCrossValidationRule(ruleName: string) {
  return computed({
    get() {
      return question.crossValidations?.find(cv => cv.crossValidationRule === ruleName)?.questionDefinitionId ?? '';
    },
    set(val) {
      if (!question.crossValidations) {
        question.crossValidations = [];
      }

      let rule = question.crossValidations.find(cv => cv.crossValidationRule === ruleName);
      if (!rule) {
        rule = { crossValidationRule: ruleName, questionDefinitionId: val, enabled: ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value };
        question.crossValidations.push(rule);
      } else {
        rule.questionDefinitionId = val;
        rule.enabled = ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value;
      }
    }
  });
}

const inSumValidation = computed({
  get() { return question.validation?.some((rule) => rule.rule === 'Sum100') ?? false; },
  set(val: boolean) {
    const hasRule = question.validation?.some((rule) => rule.rule === 'Sum100');
    if (hasRule && !val) {
      question.validation = question.validation?.filter((rule) => rule.rule !== 'Sum100');
    } else if (!hasRule && !val) {
      question.validation = [...(question.validation ?? []), { rule: 'Sum100' }];
    }
  },
});

function isCrossValidationMaxValid(value: string) {
  if (crossValidationMaxEnabled.value && value === '') {
    return 'Krydsvalidering ikke angivet';
  }

  return true;
}

function isCrossValidationMinValid(value: string) {
  if (crossValidationMinEnabled.value && value === '') {
    return 'Krydsvalidering ikke angivet';
  }

  return true;
}

</script>
