<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="theSource"
        :disabled="readonly"
        required
        variant="outlined"
        label="ID kildefelt"
      />
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { AggregatorQuestionDefinition } from '@dims/components';
import { computed } from 'vue';

const { question, readonly = false } = defineProps<{
  question: AggregatorQuestionDefinition,
  readonly?: boolean,
}>();

const theSource = computed({
  get() { return question.sourceQuestionId; },
  set(val: string) {
    question.sourceQuestionId = val;
  },
});

</script>
