<template>
  <div>
    <template v-if="variant === 'requirement-variant-flex'">
      <v-checkbox v-model="isLocked" label="Låst krav" :disabled="readonly" />
    </template>
    <template
      v-if="variant === 'requirement-variant-3-contract'
        || variant === 'requirement-variant-3-evaluation'
        || variant === 'requirement-variant-6'
        || variant === 'requirement-variant-flex'"
    >
      <v-radio-group v-model="defaultInclude" label="Default for 'Skal kravet indgå'" :disabled="readonly || isLocked">
        <v-radio value="true" label="Ja" />
        <v-radio value="false" label="Nej" />
      </v-radio-group>
    </template>
    <template v-if="variant === 'requirement-variant-flex'">
      <v-radio-group v-model="requirementTypeDefaultValue" label="Default kravtype" :disabled="readonly">
        <v-radio v-for="option in requirementTypeOptions" :key="option.key" :value="option.optionId" :label="option.text" />
      </v-radio-group>
    </template>
    <template v-if="variant === 'requirement-variant-6'">
      <v-card color="canvas">
        <v-card-text>
          <h2 class="mt-0 mb-4">
            Tekstfelt, hvis kravet indgår
          </h2>
          <v-text-field
            v-model="textFieldTitle"
            :readonly
            variant="outlined"
            density="compact"
            label="Titel"
          />
          <RichTextEditor v-model="textFieldText" ref="richTextEditor" class="mb-5" :admin="true" />
        </v-card-text>
      </v-card>
    </template>
    <template
      v-if="variant === 'requirement-variant-3-evaluation'
        || variant === 'requirement-variant-7'
        || variant === 'requirement-variant-8'"
    >
      <RichTextEditor
        v-model="defaultEvaluationText"
        label="Beskriv evalueringskriterie"
        ref="richTextEditor"
        :admin="true"
      />
    </template>
    <template v-if="variant === 'requirement-variant-flex'">
      <RichTextEditor
        v-model="defaultRequirementDescription"
        label="Beskriv kontraktkrav"
        ref="richTextEditor"
        :admin="true"
      />
      <RichTextEditor
        v-model="defaultEvaluationRequirementDescription"
        label="Beskriv evalueringskrav"
        ref="richTextEditor"
        :admin="true"
      />
      <RichTextEditor
        v-model="defaultEvaluationText"
        label="Beskriv evalueringskriterie"
        ref="richTextEditor"
        :admin="true"
      />
    </template>
    <div v-if="supportsNumbering">
      <v-checkbox v-model="numbered" label="Indgår i kravs-nummerering" :disabled="readonly" />
    </div>
    <div v-if="variant === 'requirement-variant-4'">
      <v-select
        v-model="allowedRequirementType"
        label="Vælg kravtype"
        :items="requirementOptions"
        single
      />
    </div>
    <div v-if="variant === 'requirement-variant-5'">
      <p>Kravet kan vælges til/fra af kunden.</p>
      <v-radio-group v-model="includeSelectType">
        <v-radio label="Kravet er fravalgt som udgangspunkt. Valg vises som en checkbox." value="checkbox" />
        <v-radio label="Kunden skal aktivt tage stilling til kravet. Vises som ja/nej radioknapper for kunden." value="radio" />
      </v-radio-group>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch, computed, ref } from 'vue';
import {
  SectionQuestionDefinition,
  ValueQuestionDefinition,
  RichTextEditor,
  VariantFieldType,
  AllowedRequirementTypesEnum,
  isRequirementVariant,
  OptionsQuestionDefinition,
  BooleanQuestionDefinition
} from '@dims/components';
import { getVariantQuestion } from './variantQuestions';

type IncludeSelectType = 'checkbox' | 'radio';

const { value, question, readonly = false } = defineProps<{
  // The cloned question
  value: SectionQuestionDefinition,
  // The underlying question (null if new question)
  question: SectionQuestionDefinition | null,
  readonly?: boolean
}>();

const includeSelectType = ref<IncludeSelectType>('checkbox');

if (value.variant === 'requirement-variant-5' && value.questions) {
  const includeSelector = value.questions.find((q) => q.variant === 'include-requirement');
  if (includeSelector) {
    if (includeSelector.questionType === 'Boolean') {
      includeSelectType.value = 'checkbox';
    } else if (includeSelector.questionType === 'BooleanOptions') {
      includeSelectType.value = 'radio';
    }
  }
}

watch(
  includeSelectType,
  (newValue: IncludeSelectType) => {
    if (value.variant === 'requirement-variant-5' && value.questions) {
      const includeSelector = value.questions.find((q) => q.variant === 'include-requirement');
      if (includeSelector) {
        if (newValue === 'checkbox') {
          includeSelector.questionType = 'Boolean';
        } else {
          includeSelector.questionType = 'BooleanOptions';
        }
      }
    }
  },
);

const requirementOptions: { title: string; value: AllowedRequirementTypesEnum }[] = [
  {
    title: 'Kunden skal selv vælge',
    value: 'CustomersChoice',
  },
  {
    title: 'Kontrakt krav',
    value: 'Contract',
  },
  {
    title: 'Evalueringskrav',
    value: 'Evaluation',
  }];

const variant = computed(() => value.variant);

function getVariantChildQuestion(variant1: VariantFieldType) {
  return getVariantQuestion(value, variant1);
}

const getRepeaterQuestion = computed((): ValueQuestionDefinition => {
  const q = value.questions?.find((qu) => qu.questionType === 'Repeater');
  if (!q) {
    throw new Error('Repeater question not found');
  }
  return q;
});

const allowedRequirementType = computed({
  get() {
    return getRepeaterQuestion.value.variantData?.allowedRequirementTypes === undefined ? 'CustomersChoice' : getRepeaterQuestion.value.variantData.allowedRequirementTypes;
  },

  set(val: string) {
    getRepeaterQuestion.value.variantData = getRepeaterQuestion.value.variantData ?? {};
    getRepeaterQuestion.value.variantData.allowedRequirementTypes = val;
  },
});

const includeQuestionDefault = 'true';

const includeRequirementQuestion = computed(() => {
  const includeReqQuestion = getVariantChildQuestion('include-requirement') as ValueQuestionDefinition;
  // Initialize defaultValue.
  if (includeReqQuestion.defaultValue === undefined) {
    includeReqQuestion.defaultValue = includeQuestionDefault;
  }
  return includeReqQuestion;
});

const defaultInclude = computed({
  get() {
    return includeRequirementQuestion.value.defaultValue ?? includeQuestionDefault;
  },
  set(val: string) {
    includeRequirementQuestion.value.defaultValue = val;
  },
});

const isLockedQuestion = computed(() => {
  if (variant.value !== 'requirement-variant-flex') {
    return undefined;
  }
  const isLockedVariantQuestion = getVariantChildQuestion('requirement-flex-locked') as BooleanQuestionDefinition;
  // Initialize isLockedQuestion.defaultValue.
  if (isLockedVariantQuestion.defaultValue === undefined) {
    isLockedVariantQuestion.defaultValue = 'false';
  }
  return isLockedVariantQuestion;
});

// Value used to restore includeRequirementQuestion when isLocked is unchecked.
let includeTemp: string | undefined;

const isLocked = computed({
  get() {
    if (!isLockedQuestion.value) {
      return false;
    }
    return isLockedQuestion.value.defaultValue === 'true';
  },
  set(val: boolean) {
    if (isLockedQuestion.value) {
      isLockedQuestion.value.defaultValue = val.toString();
      if (val) {
        includeTemp = includeRequirementQuestion.value.defaultValue;
        includeRequirementQuestion.value.defaultValue = 'true';
      } else {
        includeRequirementQuestion.value.defaultValue = includeTemp;
      }
    }
  },
});

const requirementTypeQuestion = computed(() => {
  const q = getVariantChildQuestion('requirement-flex-type') as OptionsQuestionDefinition;
  // Initialize default requirement type.
  if (q.defaultValue === undefined) {
    q.defaultValue = q.options?.first().optionId;
  }
  return q;
});

const requirementTypeOptions = computed(() => {
  const q = getVariantChildQuestion('requirement-flex-type') as OptionsQuestionDefinition;
  const options = q.options;
  return options;
});

const requirementTypeDefaultValue = computed({
  get() {
    return requirementTypeQuestion.value.defaultValue ?? requirementTypeQuestion.value.options?.first().optionId;
  },
  set(val: string) {
    requirementTypeQuestion.value.defaultValue = val;
  },
});

const defaultRequirementDescriptionQuestion = computed(() => getVariantChildQuestion('default-requirement-description'));
const defaultRequirementDescription = computed({
  get() {
    return defaultRequirementDescriptionQuestion.value.text;
  },
  set(text: string) {
    defaultRequirementDescriptionQuestion.value.text = text;
  },
});

const defaultEvaluationRequirementDescriptionQuestion = computed(() => getVariantChildQuestion('default-evaluation-requirement-description'));
const defaultEvaluationRequirementDescription = computed({
  get() {
    return defaultEvaluationRequirementDescriptionQuestion.value.text;
  },
  set(text: string) {
    defaultEvaluationRequirementDescriptionQuestion.value.text = text;
  },
});

const defaultEvaluationTextQuestion = computed(() => getVariantChildQuestion('default-evaluation-text'));
const defaultEvaluationText = computed({
  get() {
    return defaultEvaluationTextQuestion.value.text;
  },
  set(text: string) {
    defaultEvaluationTextQuestion.value.text = text;
  },
});

const textfield = computed(() => getVariantChildQuestion('requirement-textfield') as ValueQuestionDefinition);

const textFieldTitle = computed({
  get() {
    return textfield.value.title;
  },
  set(title: string) {
    textfield.value.title = title;
  },
});

const textFieldText = computed({
  get() {
    return textfield.value.text;
  },
  set(text: string) {
    textfield.value.text = text;
  },
});

const supportsNumbering = computed(() => ((value.variant) ? isRequirementVariant(value.variant) : false));

const numberedDefault = computed(() => supportsNumbering.value && value.variant !== 'requirement-variant-flex');

const numbered = computed({
  get() {
    // When undefined, return default.
    // (Previously true was default for all variants, and we only saved false values).
    return value.numbered ?? numberedDefault.value;
  },

  set(val: boolean) {
    value.numbered = val;
    if (question) {
      question.numbered = value.numbered;
    }
  },
});

</script>
